import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import Link from '../common/Link';

import styles from '../common/styles/events.module.scss';
import ressourcesStyles from '../../pages/styles/resources.module.scss';

const propTypes = {
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMobileBreakpoint: PropTypes.bool.isRequired,
};

const EventsList = ({ events, isMobileBreakpoint }) => (
    <section className={styles.articlesContainer}>
        {events.map(event => (
            <Link
                to={event.link}
                className={isMobileBreakpoint ? styles.resourceElementMobile : styles.resourceElement}
                key={event.eventId}
            >
                <GatsbyImage
                    image={event.image}
                    alt={event.alt}
                    className={isMobileBreakpoint ? styles.resourceElementPictureMobile : styles.resourceElementPicture}
                />
                <div className={isMobileBreakpoint ? styles.resourceElementTextContainerMobile : styles.resourceElementTextContainer}>
                    <div className={isMobileBreakpoint ? styles.resourceElementTextMobile : styles.resourceElementText}>
                        <div className={!isMobileBreakpoint && styles.resourceElementTitleContainer}>
                            <h3 className={isMobileBreakpoint ? styles.resourceElementTitle : styles.resourceElementTitleMobile}>{event.name}</h3>
                            {!isMobileBreakpoint && <p className={ressourcesStyles.resourceElementCategory}>{event.roleAtEvent}</p>}
                        </div>
                        {isMobileBreakpoint && <p className={ressourcesStyles.resourceElementCategory}>{event.roleAtEvent}</p>}
                        <p className={isMobileBreakpoint ? styles.resourceElementDatesMobile : styles.resourceElementDates}>{`${event.formattedDate} | ${event.location}`}</p>
                        <p className={isMobileBreakpoint ? styles.resourceElementSubtitleMobile: styles.resourceElementSubtitle}>
                            {event.description}
                        </p>
                        <p className={isMobileBreakpoint ? styles.itemLearnMobile : styles.resourceElementRead}>
                            View event
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={isMobileBreakpoint ? styles.chevronRightBlue : styles.chevronRight}
                            />
                        </p>
                    </div>
                </div>
            </Link>
        ))}
    </section>
);

EventsList.propTypes = propTypes;

export default EventsList;
