import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEvents, useViewport } from '../hooks';

import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import EventsList from '../components/events/EventsList';
import SubscribeBlueBoxModal from '../components/common/SubscribeBlueBoxModal';

import ogimage from '../images/witbe-og-image.jpg';

import eventsStyles from '../components/common/styles/events.module.scss';
import cssResources from './styles/resources.module.scss';
import articlesStyles from './styles/articles.module.scss';
import newStyles from './styles/newPolicy.module.scss';

const Events = () => {
    const { t } = useTranslation();
    const { isMobileBreakpoint } = useViewport();
    const { pastEvents, upcomingEvents } = useEvents();

    return (
        <Layout title='Events - Witbe' isEvents>
            <SEO
                title='Witbe Events | Quality Assurance & Proactive Monitoring Solutions'
                ogDescription='Discover Witbe’s upcoming events showcasing quality assurance & proactive monitoring solutions. Learn how our tech improves operations & meet Witbe experts.'
                description='Discover Witbe’s upcoming events showcasing quality assurance & proactive monitoring solutions. Learn how our tech improves operations & meet Witbe experts.'
                article='article'
                image={ogimage}
            />

            <div id={(isMobileBreakpoint ? eventsStyles : newStyles).mainImageWrapper}>
                <div className={(isMobileBreakpoint ? eventsStyles : newStyles).mainImageTextWrapper}>
                    <h1 className={(isMobileBreakpoint ? eventsStyles : newStyles).mainImageTitle}>
                        {t('siteMetadata.events.title')}
                    </h1>
                </div>
            </div>
            <section id={isMobileBreakpoint ? eventsStyles.articlesContainerMobileNoMargin : articlesStyles.articlesContainerNoMargin}>
                <div className={isMobileBreakpoint ? eventsStyles.articlesMainMobile : cssResources.articlesMain}>
                    <EventsList events={upcomingEvents} isMobileBreakpoint={isMobileBreakpoint} />
                    <h2 className={(isMobileBreakpoint ? eventsStyles : cssResources).pastEventsTitle}>Past events</h2>
                    <EventsList events={pastEvents} isMobileBreakpoint={isMobileBreakpoint} />
                </div>
                {!isMobileBreakpoint && (
                    <aside className={cssResources.sidebarRight}>
                        <div className={cssResources.listsContainer}>
                            <SubscribeBlueBoxModal />
                        </div>
                    </aside>
                )}
            </section>
        </Layout>
    );
}

export default Events;
